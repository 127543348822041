<template>
<div class="app-exchanges-accounting-page">
  <div class="page__wrapper">
    <div class="page__header-actions">
      <a-button type="text" @click="toExchangesPage" :style="{ padding: '0' }">
        <template #icon>
          <ArrowLeftOutlined />
        </template>
        返回首頁
      </a-button>
    </div>

    <div class="page__body">
      <a-typography-title :level="1">收支紀錄</a-typography-title>

      <a-divider />

      <!-- 收支賬戶總覽 -->
      <div class="body-item">
        <a-typography-title :level="3">收支賬戶總覽</a-typography-title>

        <a-table
          :dataSource="overviewRecords"
          :columns="overviewColumns"
          :loading="spinning"
          :pagination="false"
          :style="{ marginBottom: '8px' }"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'account_receivable'">
              <span :style="{color: record.account_receivable>0?'red':'black'}">{{ record.account_receivable }}</span>
            </template>
          </template>
        </a-table>

        <div align="right">
          <div :style="{ textAlign: 'right' }">總共支出 t-Coin： <span :style="{ display: 'inline-block', fontWeight: 'bold', width: '60px' }">$ {{ overviewStat.totalExpenditureTcoin }}</span> t-Coin</div>
          <div :style="{ textAlign: 'right' }">總共收入 t-Coin：  <span :style="{ display: 'inline-block', fontWeight: 'bold', width: '60px' }">$ {{ overviewStat.totalReceivedTcoin }}</span> t-Coin</div>
          <div :style="{ textAlign: 'right' }">總共收入 NTD：  <span :style="{ display: 'inline-block', fontWeight: 'bold', width: '60px' }">$ {{ overviewStat.totalReceivedNTD }}</span> NTD</div>
          <div :style="{ textAlign: 'right' }">總共應收帳款：  <span :style="{ display: 'inline-block', fontWeight: 'bold', width: '60px' }">$ {{ overviewStat.totalAR }}</span> NTD</div>
        </div>
      </div>
      <!-- 收支賬戶總覽 -->

      <a-divider :style="{ margin: '40px 0' }" />

      <!-- 收支列表 -->
      <div class="body-item">
        <a-typography-title :level="3">收支列表</a-typography-title>

        <a-radio-group v-model:value="recordCurrencyType" :style="{ marginBottom: '20px' }">
          <a-radio-button value="t-Coin">t-Coin</a-radio-button>
          <a-radio-button value="NTD">NTD</a-radio-button>
        </a-radio-group>

        <a-table
          :dataSource="filteredRecordsData"
          :columns="coinExportColumns"
          :loading="spinning"
          :style="{ marginBottom: '8px' }"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'type'">
              <span :style="{ color: record.type=='received' ? '#689F38' : 'red' }">{{ record.type }}</span>
            </template>

            <template v-if="column.key === 'target_name'">
              {{ record.target_name }}兌換所
            </template>

            <template v-if="column.key === 'amount'">
              <span :style="{ fontWeight: 'bold' }">{{ record.amount }}</span>
            </template>

            <!-- <template v-if="column.key === 'paid'">
              <template v-if="record.paid">
                <a-tag color="#108ee9">Paid</a-tag>
              </template>
              <template v-else>
                <a-popconfirm
                  title="Confirm to update paid?"
                  ok-text="Confirm"
                  cancel-text="No"
                  @confirm="confirmUpdatePaid(record.id)"
                >
                  <a-tag color="#f50" :style="{ cursor: 'pointer' }">Not Paid</a-tag>
                </a-popconfirm>
              </template>
            </template> -->

            <template v-if="column.key === 'created_at'">
              {{ DateUtil.formatDatetime(record.created_at) }}
            </template>
          </template>
        </a-table>
      </div>
      <!-- 收支列表 -->
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { defineComponent } from 'vue'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'
import DateUtil from '@/utils/DateUtil'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {
    ArrowLeftOutlined
  },
  setup() {
    return {
      DateUtil
    }
  },
  async mounted() {
    await this.init();
  },
  data() {
    return {
      spinning: false,

      exchangesData: [],

      /* 收支帳戶總覽 */
      overviewRecords: [],
      overviewStat: {
        totalExpenditureTcoin: 0,
        totalReceivedTcoin: 0,
        totalReceivedNTD: 0,
        totalAR: 0
      },
      overviewColumns: [{
        title: '兌換所',
        dataIndex: 'exchange_name',
        key: 'exchange_name'
      }, {
        title: '支出的 t-Coin',
        dataIndex: 'expenditure_tcoin',
        key: 'expenditure_tcoin'
      }, {
        title: '收到的 t-Coin',
        dataIndex: 'received_tcoin',
        key: 'received_tcoin'
      }, {
        title: '收到的 NTD',
        dataIndex: 'received_ntd',
        key: 'received_ntd'
      }, {
        title: '應收帳款',
        dataIndex: 'account_receivable',
        key: 'account_receivable'
      }],

      /* 收支列表 */
      recordCurrencyType: 't-Coin',
      recordsData: [],
      coinExportColumns: [{
        title: 'Type',
        dataIndex: 'type',
        key: 'type'
      }, {
        title: 'Target Name',
        dataIndex: 'target_name',
        key: 'target_name'
      }, {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount'
      }, {
        title: 'Note',
        dataIndex: 'note',
        key: 'note'
      }, {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at'
      }]
    }
  },
  computed: {
    filteredRecordsData() {
      let data = [];

      this.recordsData.forEach(item => {
        if (item.currency == this.recordCurrencyType) {
          data.push(item);
        }
      });

      data = data.sort(function (a, b) {
        return a.created_at > b.created_at ? 1 : -1;
      });

      return data
    }
  },
  methods: {
    /* router */
    toExchangesPage() {
      this.$router.push({ name: 'AppExchanges' });
    },

    /* init functions */
    async init() {
      this.spinning = true;
      await this.getExchangeAccounts();
      await this.getTransferRecords();
      this.calBalanceOverview();
      this.spinning = false;
    },

    /* functions */
    // 計算收支總覽
    calBalanceOverview() {
      this.overviewRecords = [];

      // 計算對各個兌換所支出多少 t-coin
      let accountingRecord = {}; // exchange: expenditure_tcoin, received_tcoin, received_ntd

      this.recordsData.forEach(record => {
        if (!Object.keys(accountingRecord).includes(record.target_name)) {
          accountingRecord[record.target_name] = {
            expenditure_tcoin: 0,
            received_tcoin: 0,
            received_ntd: 0
          }
        }

        // 判斷是支出還是收入 & 幣種
        let transferType = ''; // expenditure|received
        if (record.from_user.id == this.$store.state.auth.info.id) transferType = 'expenditure';
        else transferType = 'received';
        
        // cal expenditure_tcoin
        if (transferType=='expenditure' && record.currency=='t-Coin') {
          accountingRecord[record.target_name].expenditure_tcoin += record.amount;
          this.overviewStat.totalExpenditureTcoin += record.amount;
        }

        // cal received_tcoin
        if (transferType=='received' && record.currency=='t-Coin') {
          accountingRecord[record.target_name].received_tcoin += record.amount;
          this.overviewStat.totalReceivedTcoin += record.amount;
        }

        // cal received_ntd
        if (transferType=='received' && record.currency=='NTD') {
          accountingRecord[record.target_name].received_ntd += record.amount;
          this.overviewStat.totalReceivedNTD += record.amount;
        }
      });

      // 轉換成 records
      Object.keys(accountingRecord).forEach(exchangeName => {
        const record = {
          exchange_name: exchangeName,
          expenditure_tcoin: accountingRecord[exchangeName].expenditure_tcoin,
          received_tcoin: accountingRecord[exchangeName].received_tcoin,
          received_ntd: accountingRecord[exchangeName].received_ntd,
          account_receivable: accountingRecord[exchangeName].expenditure_tcoin - accountingRecord[exchangeName].received_tcoin - accountingRecord[exchangeName].received_ntd
        }
        this.overviewRecords.push(record);

        this.overviewStat.totalAR += record.account_receivable;
      }); 
    },

    /* Update Paid Status */
    // async confirmUpdatePaid(id) {
    //   const url = process.env.VUE_APP_API + `/admin-transactions/${id}`;
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${this.$store.state.auth.token}`
    //     }
    //   }

    //   try {
    //     const params = { paid: true }
    //     await axios.put(url, params, config);

    //     // update record paid status
    //     this.recordsData.forEach(item => {
    //       if (item.id == id) item.paid = true;
    //     });

    //     message.success('Update paid success');
    //   } catch (error) {
    //     console.log(error);
    //     message.error('Update paid failed');
    //   }
    // },

    /* api */
    async getExchangeAccounts() {
      const url = process.env.VUE_APP_API + '/users?role=3';
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`
        }
      }
      const response = await axios.get(url, config);
      console.log(response.data);
      this.exchangesData = response.data;
    },
    async getTransferRecords() {
      // init
      this.recordsData = [];
      this.totalTransferOutAmount = 0;

      // find transactions
      const url = process.env.VUE_APP_API + `/admin-transactions?coop_info=${this.$store.state.auth.info.coop_info.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`
        }
      }
      const response = await axios.get(url, config);
      const resData = response.data;

      // combine exchage_info
      for (let i=0; i<resData.length; i++) {
        const record = resData[i];

        // Coop -> Exchange
        // let record = {
        //   id: item.id,
        //   type: '',
        //   target_name: '',
        //   amount: item.amount,
        //   currency: item.currency,
        //   note: item.note,
        //   tx_hash: item.tx_hash,
        //   tx_id: item.tx_id,
        //   created_at: item.created_at
        // };

        let targetKey = ''; // to_user|from_user
        if (record.from_user.id == this.$store.state.auth.info.id) {
          record.type = 'expenditure';
          targetKey = 'to_user';
        } else { // Exchange -> Coop
          record.type = 'received';
          targetKey = 'from_user';
        }

        for (let j=0; j<this.exchangesData.length; j++) {
          const exchange = this.exchangesData[j];

          if (record[targetKey].id == exchange.id) {
            record.target_name = exchange.ex_user_info.exchange_name;
            break
          }
        }

        this.recordsData.push(record);
        this.totalTransferOutAmount += record.amount;
      }
    }
  }
})
</script>

<style lang="scss">
.app-exchanges-accounting-page {
  .page__wrapper {
    width: 950px;
    margin: 0 auto;
  }

  .page__header-actions {
    margin-bottom: 12px;
  }
}
</style>